import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { QsIdsService } from '../../services/qs-ids.service';
import { environment } from '../../../environments/environment';
const BACKEND_URL = environment.qsServiceAPIUrl;

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css'],
})
export class DescriptionComponent implements OnInit {
  serviceID: string = '';
  orderDetails: any;
  phoneNumber: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private datePipe: DatePipe,
    private QsIdsService: QsIdsService
  ) {}

  ngOnInit() {
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.route.queryParams.subscribe((params) => {
      this.serviceID = params['serviceID'];
      console.log('serviceID : ', this.serviceID);
    });
    if (this.serviceID) {
      this.fetchOrderDetails();
    }
  }

  goBack() {
    this.location.back();
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIdsService.getServiceLabel(serviceName);
  }

  formatDate(date: string): string {
    const formattedDate = new Date(date);
    return this.datePipe.transform(formattedDate, 'dd/MM/yyyy') || '';
  }

  orderStatusSwitch(orderStatus: String): any {
    switch (orderStatus) {
      case 'CAN':
        return 'Cancelled';
      case 'COM':
        return 'Completed';
      case 'ACC':
        return 'Accepted';
      case 'PRO':
        return 'Progress';
      case 'ASS':
        return 'Assigned';
    }
  }

  paymentStatus(paymentStatus: String): any {
    switch (paymentStatus) {
      case 'NPD':
        return 'Not Paid';
      case 'PAID':
        return 'PAID';
    }
  }

  paymentMethod(paymentMethod: String): any {
    switch (paymentMethod) {
      case 'COD':
        return 'COD';
      case 'UPI':
        return 'UPI';
      case 'TBD':
        return 'TBD';
    }
  }

  userPhoneNumber:any;
  fetchOrderDetails() {
    const profileID = localStorage.getItem('profileID');
    // Make a GET request to fetch order details
    this.http
      .get(`${BACKEND_URL}/orderDescription/${this.serviceID}`)
      .subscribe(
        (response) => {
          console.log('response : ', response);
          this.orderDetails = response;
          // this.isChecked = this.orderDetails[0].serviceProvider.isAccepted;

          const serviceProvider = this.orderDetails[0].serviceProvider.find(
            (provider: any) => provider.phoneNumber.toString() === this.phoneNumber
          );

          this.userPhoneNumber = this.orderDetails[0].address.deliveryNumber;

          console.log("the userPhoneNumber is: ", this.userPhoneNumber);
          console.log('the serviceProvider is: ', serviceProvider);
          console.log('the phoneNumber is: ', this.phoneNumber);
          // Set isChecked based on the found service provider's isAccepted value
          if (serviceProvider) {
            this.isChecked = serviceProvider.isAccepted;
          } else {
            console.error(
              'Service provider with the specified phone number not found.'
            );
          }
        },
        (error) => {
          console.error('Error fetching order details:', error);
          // Handle error, show an error message, etc.
        }
      );
  }

  openGoogleMapsDirections(lat: any, long: any) {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            Swal.fire({
              icon: 'error',
              title: 'Permission Denied',
              text: 'Please enable location services to use this feature.',
            });
          } else {
            // Construct the Google Maps URL with the destination coordinates.
            let googleMapsUrl: string;

            console.log(lat, long);

            if (lat && long) {
              console.log(lat, long);
              googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${long},${lat}`;
              // Open the Google Maps URL in a new tab or window.
              if (typeof window !== 'undefined') {
                window.open(googleMapsUrl, '_blank');
              }
            }
          }
        });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Unsupported Browser',
        text: 'Geolocation is not supported by this browser.',
      });
    }
  }

  updateStatus: string = 'COM';
  selectedStatus: string = 'Service Status';
  update: boolean = false;
  paymentMethods: string = '';
  totalAmount: number | undefined;
  hoursWorked: number | undefined;

  setStatus(status: string) {
    if (status === 'COM') {
      this.updateStatus = status;
      this.selectedStatus = 'Completed';
      console.log('Status set to Completed:', this.updateStatus);
    } else if (status === 'CAN') {
      this.selectedStatus = 'Cancelled';
      console.log('Status set to Cancelled');
    }
  }

  setPaymentMethod(method: string) {
    this.paymentMethods = method;
    console.log('Payment method set to:', this.paymentMethods);
  }

  onUpdate() {
    this.update = !this.update;
  }

  close() {
    this.update = !this.update;
    this.updateStatus = 'COM';
    this.selectedStatus = 'Service Status';
    this.paymentMethods = '';
  }

  save() {
    if (!this.updateStatus || !this.paymentMethods || !this.totalAmount) {
      Swal.fire({
        icon: 'warning',
        title: 'Please choose the update status',
        text: 'You need to select a status before confirming the order update.',
      });
      return; // Exit the method if updateStatus is not set
    }

    Swal.fire({
      title: 'Confirm Order update',
      text: 'Are you sure you want to update this service?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, mark as update',
      confirmButtonColor: '#218b82',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        const requestBody = {
          updateStatus: this.updateStatus,
          paymentMethods: this.paymentMethods,
          totalAmount: this.totalAmount,
          hoursWorked: this.hoursWorked,
          userPhoneNumber: this.userPhoneNumber,
        };
        // If the user confirms, proceed with the HTTP request
        this.http
          .put(BACKEND_URL + `/OrderDelivered/${this.serviceID}`, requestBody)
          .subscribe(
            (response: any) => {
              console.log('Backend response:', response);
              Swal.fire('Success', response['message'], 'success');
              this.fetchOrderDetails();
              this.update = !this.update;
            },
            (error) => {
              console.error('Update error:', error);
              Swal.fire('Error', 'Failed to update order', 'error');
              // Handle error responses or UI updates for errors
            }
          );
      }
    });
  }

  isChecked: boolean = false; // or false, depending on the initial state

  onToggle(event: Event) {
    this.isChecked = (event.target as HTMLInputElement).checked;
    console.log('Switch state:', this.isChecked);
    this.sendSwitchState(this.isChecked);
  }

  sendSwitchState(state: boolean) {
    const url = BACKEND_URL + `/OrderAccept/${this.serviceID}`;
    const body = {
      switchState: state,
      phoneNumber: this.phoneNumber,
    };

    this.http.post(url, body).subscribe(
      (response) => {
        console.log('Successfully sent switch state:', response);
        this.fetchOrderDetails();
      },
      (error) => {
        console.error('Error sending switch state:', error);
      }
    );
  }
}
